<template>
  <div :class="theme">
    <div style="padding: 20px">
      <h2>模型树用户协议</h2>
      <p></p>
      <p>甲方：逑知（深圳）教育科技有限公司（以下简称“甲方”）</p>
      <p></p>
      <p>乙方：“模型树”注册用户（以下简称用户或“您”）</p>
      <p></p>
      <p>
        以下是模型树《服务协议》，请您在注册与使用模型树写作前务必审慎阅读并充分理解协议中相关条款内容，当您点击注册或使用关联账户登录模型树并使用服务时，即代表您已同意协议内容。本协议将在必要时进行修订，如您不同意本协议或其修改版本，请您立即停止注册或使用模型树所提供的全部服务。您可以访问甲方官网随时了解最新版本的服务协议。本协议为
        2022年03月07日版本模型树《服务协议》，如您不同意更新后的模型树《服务协议》内容，请您立即停止使用模型树并及时删除已在模型树中发布的链接，如您继续使用或保持链接，则视为您已同意修改后的《服务协议》内容。
      </p>
      <p></p>
      <p>一、账户注册及使用说明：</p>
      <p></p>
      <p>
        1.您应当确保您提交的本人注册信息真实、有效、合法，模型树平台无须对您提交的注册信息承担责任，包括但不限于鉴别核实您提交的资料是否真实、有效、合法。同时您应当保证自己具有法律规定的完全民事权利能力和民事行为能力,
        能够独立承担民事责任的自然人、法人或其他组织。
      </p>
      <p></p>
      <p>2.您应当妥善保管自己的用户名及密码信息：</p>
      <p></p>
      <p>
        （1）若您发现您的模型树账号发生未授权的使用或遭受其他安全问题，您应当及时通知模型树平台。
      </p>
      <p></p>
      <p>（2）倘若您未能妥善保管好自己的账号，由此产生的损失，由您自己承担。</p>
      <p></p>
      <p>
        3.您应当对您使用模型树账户的行为（包括您未能妥善保管账号，他人使用模型树账号的行为）负责，甲方有权制定相关规则，并单方面采取必要的措施，规范您的账户行为与发布内容。
      </p>
      <p></p>
      <p>
        （1）您的操作行为如违反包括但不限于以下情况：非法、歧视、恐吓、诽谤、色情、淫秽、谣言、虚假、夸大、低俗、诱导、版权等违反包括中国法律、版权法规、商业竞争和广告宣传等在内的相关组织的法规（包括行政命令和相关协议条款）、公序良俗等，则您的行为会被甲方限制且由此造成的法律责任，由您自己承担。甲方无法保证甲方平台用户发布内容的真实性、合法性，如您发现平台中用户发布内容侵害您的权益或公共利益，您可依据【侵权投诉】下条款指引联系甲方。
      </p>
      <p></p>
      <p>
        （2）您不得上传或者发布推送垃圾信息，或上传计算机病毒程序或者恶意代码等内容及相关信息或服务，也不得恶意攻击“模型树”服务器；您也不得以口头或者文字方式攻击（包括恐吓、报复等）甲方用户、员工、合作伙伴和本产品及服务的其他使用者。如出现上述情况，甲方有权终止为您提供更多的服务内容，情节严重者，甲方将保留追究您法律责任的权利。
      </p>
      <p></p>
      <p>
        （3）若您的账户流量存在恶意使用或明显超过正常情况的使用，我们有权直接暂停或关闭对您的服务。
      </p>
      <p></p>
      <p>
        （4）本服务依照本协议向中华人民共和国境内用户提供，您在本协议提供的服务期间，应遵守全部中国法律、法规和规章。如果因您的违法行为或违反本协议的行为致使甲方蒙受损失，您应当积极采取措施减少甲方可能遭受的损失，并承担相应的赔偿责任。
      </p>
      <p></p>
      <p>
        （5）您在模型树平台以外使用您制作的内容
        ，与模型树平台无关，您应当知道并理解，您需要为自己的上述行为负责。
      </p>
      <p></p>
      <p>
        （6）如果乙方存在任何触犯中国法律法规的行为或违反本协议的行为，甲方有权根据行为的严重性自行决定立即暂停或终止乙方对本服务的使用，甲方无须就服务暂停或终止对乙方承担任何责任，并且无须返还乙方已支付的费用。
      </p>
      <p></p>
      <p>
        4.按现状提供服务：甲方产品支持市面上主流浏览器（IE10、safari、谷歌浏览器），您应当理解，倘非甲方技术问题致使您永久不能使用模型树平台，甲方不对此负直接或间接责任。另外，您在使用甲方支持的上述载体时，请您遵守其他载体的使用规范，如您的内容被包括但不限于微信、微博、谷歌浏览器等第三方服务管控或限制，给您造成损失的，甲方不承担相应的责任。
      </p>
      <p></p>
      <p>
        5.&nbsp;乙方在进行生成或编辑文案广告时，需严格执行《互联网广告管理暂行办法》，确保发布内容不违法不侵权，您利用本平台制作或发布在本平台上的广告，需要明确“广告”字样。如您使用本产品与服务的行为违反相关法律法规，您需为自己的行为负责。
      </p>
      <p></p>
      <p>二、内容和版权：</p>
      <p></p>
      <p>1.模型树平台为向您提供的编辑素材作出如下版权说明：</p>
      <p></p>
      <p>
        模型树所提供的部分公开资料来源于互联网，转载的目的在于传递更多信息及用于网络分享，并不代表本站赞同其观点和对其真实性负责，也不构成任何其他建议。本站部分作品是由网友自主投稿和发布、编辑整理上传，对此类作品本站仅提供交流平台，不为其版权负责。用户不应将素材或AI所生成文字内容用于商业用途。用户在使用本网站生成的文字内容时，有义务对涉及知识产权的内容进行出处标注。若因用户违反《信息网络传播权保护条例》产生的任何法律后果及风险，由用户自己承担。您不可再次以免费或付费方式许可他人使用模型树中为您提供的素材（特别是向模型树竞品网站中提供上述素材），如您有类似侵权行为，模型树与原创作者将保留向您追究法律责任的权利。
      </p>
      <p></p>
      <p>
        2.您清楚并明白用户对其提供的任何内容负责，模型树不对非自身官方提供以外的内容合法性负责；除非模型树事先收到相关通知，模型树将用户视为在模型树官网上传或发布的内容的著作权人或合法的被授权人。根据监管要求，模型树有权随时检查平台上的任何内容是否违约违法；如果发现此类内容违反用户协议或违反“模型树”产品制作要求，在其他方面令人反感或违反相关法律法规等，模型树可随时并有绝对自由裁量权进行核查、转移、拒绝、修改和/或删除内容，而不需事先通知。
      </p>
      <p></p>
      <p>
        3.您应当理解，如您在“模型树”制作的文字内容，你需要对您在本网站上上传或发布的内容负责，如该内容涉嫌侵权并对甲方引发不良影响，您需要承担赔偿甲方及其分公司、关联公司、代理人或其他合作伙伴及员工的全部责任，并使其免受损害。
      </p>
      <p></p>
      <p>
        4.如您因购买了第三方制作作品而侵犯他人知识产权，或因该作品违反法律法规的作品而被追究法律责任，用户应向作品的第三方作者追究责任和索赔，模型树不承担任何法律责任，但会对您的维权行为提供积极帮助。
      </p>
      <p></p>
      <p>三、侵权投诉：</p>
      <p></p>
      <p>
        1.当您发现平台中有侵犯您权利的内容，请您向下方提供的联系发送送交初步侵权证据，模型树平台会依据《侵权责任法》第三十六条及《信息网络传播权保护条例》第十四条，采取删除、屏蔽、断开连接等必要措施。有效通知包括：
      </p>
      <p></p>
      <p>（1）权利人姓名（名称）、联系方式和地址及权利人身份证明；</p>
      <p></p>
      <p>（2）要求删除或者断开链接的侵权作品网络地址；</p>
      <p></p>
      <p>（3）构成侵权的初步证明，具体包括：权属凭证与侵权事实初步证明等。</p>
      <p></p>
      <p>
        同时，您应当理解，权利人需要对通知书的真实性负责，因您虚假通知引起的法律责任由您自己承担。
      </p>
      <p></p>
      <p>
        2.当模型树接到上条所述的有效通知时，会采取删除、屏蔽、断开链接等必要措施，并将该有效通知转送提供链接的用户，如该用户的联系方式不明，模型树无法转送的，将会将有效通知及时公告。
      </p>
      <p></p>
      <p>
        3.如您接到模型树转送的有效通知，认为自己并无相应侵权行为，并希望恢复链接，您需要向模型树提供下列书面说明，并承诺对对书面说明的真实性负责。模型树会依据《信息网络传播保护条例》第十六条，立即恢复链接。
        书面说明应当包含下列内容:
      </p>
      <p></p>
      <p>（一）服务对象的姓名（名称）、联系方式和地址；</p>
      <p></p>
      <p>（二）要求恢复的作品、表演、录音录像制品的名称和网络地址；</p>
      <p></p>
      <p>（三）不构成侵权的初步证明材料。</p>
      <p></p>
      <p>
        同时，模型树会将上述书面说明转送至侵权投诉人。此后，该纠纷由双方交涉，自由选择协商、诉讼、仲裁等争议解决方式，待双方处理完毕后，一同通知模型树平台处理，如无后续通知，该链接将保持现状。
      </p>
      <p></p>
      <p>
        4.任何第三方（包括但不限于企业、公司、单位或个人等）认为模型树用户发布的任何信息侵犯其合法权益的，包括但不限于以上两点，在有充分法律法规及证据足以证明的情况下，均可以通过下列联系方式通知模型树：
      </p>
      <p></p>
      <p>
        公司地址：广东省深圳市南山区粤海街道高新区社区高新南七道018号高新工业村R3-B栋01层211
      </p>
      <p></p>
      <p>客服微信号：siweimoxing003</p>
      <p></p>
      <p>四、知识产权条款：</p>
      <p></p>
      <p>
        本服务是由模型树开发。本服务的一切版权、商标权及商标申请权、专利权及专利申请权、商业秘密等知识产权，以及与本服务相关的所有信息内容，包括但不限于：文字表述及其组合、图标、图饰、图表、色彩、界面设计、版面框架、有关数据、印刷材料或电子文档等均受中华人民共和国著作权法、商标法、专利法、反不正当竞争法和相应的国际条约以及其他知识产权法律法规的保护，除涉及第三方授权的软件或技术外，模型树享有上述知识产权。
      </p>
      <p></p>
      <p>
        除本协议另有规定外，未经模型树书面同意，您不得为任何营利性或非营利性的目的直接或间接对上述产品、服务、信息、材料的任何部分进行使用、复制、修改、抄录、传播或与其他产品捆绑使用、销售。如未经许可实施上述行为，您需为你的行为承担法律责任。
      </p>
      <p></p>
      <p>
        您在模型树网平台上传的作品，无论是收费还是免费的，若发生第三方侵权行为，您授权我们可以就您的作品单独或者与本平台上的部分或者全部被侵权作品一起，作为经您授权的被许可人，以自己的名义向侵权人主张权利或者提起诉讼、仲裁、举报、投诉等。该类诉讼仲裁等救济方式成本由我们承担，所得赔偿由我们享有。
      </p>
      <p></p>
      <p>
        上述约定不影响您单独就您的作品向侵权人主张权利，但就同一侵权行为我们已经起诉或者其他方式维权的除外。您授权免费使用的作品转为收费使用后，对于之前已经使用的免费用户，您同意按照本协议规定不予追究，不要求已经发布的内容予以删除。
      </p>
      <p></p>
      <p>五、付费相关条款：</p>
      <p></p>
      <p>
        1.“模型树”提供免费和付费产品供您使用，甲方有权依据市场需求调整或新推出付费产品、功能或服务及相关的服务费用。具体调整均以甲方官网公示为准。
      </p>
      <p></p>
      <p>
        2.您有权自由选择决定是否使用“模型树”中的付费产品、功能或服务。甲方会尽力但不保证对您的每一次付费操作进行确认提醒，包括但不限于付费金额和服务内容。
      </p>
      <p></p>
      <p>
        3.甲方可能要求您提供经甲方认可的发卡机构发行的信用卡或借记卡和其他第三方支付平台，以收取您购买服务的对应费用。
      </p>
      <p></p>
      <p>
        4.服务费用不包含因您使用“模型树”，访问互联网或移动网络而产生的相关费用，您需要自行承担因使用“模型树”服务而向包括网络运营商在内的第三方支付的相关费用，例如，互联网接入费、手机流量费、手机短信费等。
      </p>
      <p></p>
      <p>
        5.甲方会尽力但不保证提供完善的付费产品、功能或服务的相关内容，提供方式包括但不限于页面提醒和客服服务。您有权在选择购买前，通过以上渠道，详细了解所有产品功能和服务细则。甲方不承担因您未充分了解付费产品、功能或服务的内容，而提出的退款或赔偿诉求。本平台服务属于知识产权服务不适用七天无理由退换货规定。
      </p>
      <p></p>
      <p>
        6.您在购买甲方增值服务（包含但不限于购买积分、账号升级、功能服务、内容素材、使用权限等），因您主观原因（包含但不限于不愿继续持有、非甲方产品或服务问题等）产生的退款行为，甲方有权不进行受理。
      </p>
      <p></p>
      <p>
        7.您有权充分了解或评估使用甲方产品存在的风险，包含但不限于网络问题，信息安全，版权问题以及其他非甲方服务所导致的产品使用异常。
      </p>
      <p></p>
      <p>
        8.甲方依据现有技术提供服务，并全力维护网站的正常运行，但甲方无法随时预见任何技术上的问题或其他困难，该等困难可能导致您的使用受到影响（包含但不限于制作、推广等），您需谨慎考虑使用甲方操作平台所提供的服务可能带来的风险，如因此类问题产生的退款行为，甲方不负任何直接或间接赔偿责任。
      </p>
      <p></p>
      <p>
        9.甲方提供与其他网站或资源的链接，您可能会因此连接至其他运营商经营的网站，但不表示甲方与这些运营商有任何关系，甲方不对上述链接网站及其网页内容进行管理监督。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、物品或服务所产生的任何损害或损失，甲方有权协商处理，但无承担任何直接或间接赔偿责任的义务。
      </p>
      <p></p>
      <p>
        10.您有权充分了解甲方给予的产品使用和功能权限，并根据自身需自愿选择付费使用您所需要的功能和服务。如因您自身原因（包含但不限于未仔细阅读相关服务协议、审核规则、账号权限等）未能充分了解甲方提供的服务，在购买服务后未能得到预期的效果（包含但不限于消耗类服务、数据类服务，推广类服务等）产生的退款行为，以及相关的损害或损失，甲方不负任何直接或间接赔偿责任。
      </p>
      <p></p>
      <p>
        11.您在甲方所产生的付款，可能被用作支付某些第三方版权与服务的费用，因第三方使用过程中产生的纠纷，甲方有权协商处理，但无承担任何直接或间接赔偿责任的义务。
      </p>
      <p></p>
      <p>
        12.在任何情况下，甲方均无须对任何间接性、后果性、惩戒性、偶然性、特殊性或刑罚性的损害（包括但不限于乙方因使用甲方服务而遭受损失及对他人造成的损失）承担责任。
      </p>
      <p></p>
      <p>
        13.甲方对乙方承担的全部责任总额，无论因何原因（基于合同法、侵权法或其他法规）或何种行为方式产生，始终不超过乙方在服务期内因使用甲方服务而已经支付给甲方的费用。
      </p>
      <p></p>
      <p>
        14.甲方会尽力对您在使用付费产品，功能和服务过程中遇到的问题，提供可行的解决方案，并最终解决您的问题。如非甲方产品，功能和服务问题所导致的使用异常，甲方不负任何直接或间接赔偿责任。
      </p>
      <p></p>
      <p>六、服务终止条款：</p>
      <p></p>
      <p>
        甲方可随时且不需事先通知，立即终止或暂停您的所有或部分账户服务功能。此类服务终止的原因包括但不限于以下：
      </p>
      <p></p>
      <p>1.违反本服务协议；</p>
      <p></p>
      <p>2.需要听从法庭传票、法律命令或遵循法律程序；</p>
      <p></p>
      <p>3.突发的自然灾害导致的问题；</p>
      <p></p>
      <p>4.战争、军事行动、暴动或武装叛乱；</p>
      <p></p>
      <p>5.您没有支付所需付费服务相关的费用；</p>
      <p></p>
      <p>6.因不可抗力致使不能提供服务的情况；</p>
      <p></p>
      <p>
        7.如您提供不具有自主知识产权的作品或侵犯他人知识产品，情节严重者，甲方将解除与您的服务协议，如由此产生的法律责任，由您承担；
      </p>
      <p></p>
      <p>
        8.如您的作品内含有违反法律法规内容，我们将与您解除服务协议，由此产生的法律责任由您承担；
      </p>
      <p></p>
      <p>
        9.服务终止：在终止本协议后的合理时间内，甲方将有权下架所有您上传至“模型树”的作品，您及其他用户将无法再浏览您的作品；
      </p>
      <p></p>
      <p>10.您长期不再使用甲方账户。</p>
      <p></p>
      <p>七、隐私政策：</p>
      <p></p>
      <p>
        使用本服务，即表示您同意甲方合法收集和使用有关您及您所使用本服务的技术性或诊断性信息。收集到的这些信息将用于改进甲方产品和服务的改进，提升我们的服务品质。
      </p>
      <p></p>
      <p>
        甲方不会超出提供服务目的使用您的信息，也不会向第三方共享您的信息。但以下情况除外：
      </p>
      <p></p>
      <p>1.您同意让第三方共享资料；</p>
      <p></p>
      <p>2.与国家安全、国防安全直接相关的；</p>
      <p></p>
      <p>3.与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p></p>
      <p>4.与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
      <p></p>
      <p>
        5.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又难以迅速得到本人同意的；
      </p>
      <p></p>
      <p>6.所收集的个人信息是您自行向社会公众公开的；</p>
      <p></p>
      <p>
        7.从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
      </p>
      <p></p>
      <p>8.根据您的要求签订和履行合同所必需的；</p>
      <p></p>
      <p>
        9.用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
      </p>
      <p></p>
      <p>
        10.出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
      </p>
      <p></p>
      <p>11.法律法规规定的其他情形。</p>
      <p></p>
      <p>八、其他条款：</p>
      <p></p>
      <p>1.甲方将以专业的方式按现状提供产品及服务，但并不保证：</p>
      <p></p>
      <p>1）产品及服务满足您的所有需求或您的所有期待；</p>
      <p></p>
      <p>
        2）产品及服务没有任何失误或缺陷，或产品及服务中的所有错误都会被及时修正。
      </p>
      <p></p>
      <p>
        2.您了解甲方使用第三方的运营商和网络提供商提供必要的硬件、软件、存储或网络来运行模型树服务。甲方无须因为第三方的，或者不在甲方控制范围内的原因（如不可抗力）造成的运行问题（如服务中断、停顿、中止等）负责。
      </p>
      <p></p>
      <p>3.甲方保留不断修改调整产品或服务的权利，无须事先通知乙方。</p>
      <p></p>
      <p>
        4.如果本协议的某一条款或某一条款的一部分无效或不可执行，不影响本协议其他条款的有效性，无效或不可执行的条款将被视作已从本协议中删除。
      </p>
      <p></p>
      <p>
        5.本协议受中华人民共和国法律管辖。在执行本协议过程中如发生纠纷，双方应及时协商解决。协商不成时，任何一方均有权向甲方所在地人民法院提起诉讼。
      </p>
      <p></p>
      <p>6.本协议中未明确授予您的权利将由甲方保留。</p>
      <p></p>
      <p>
        7.若有任何疑问，请通过浏览http://www.moxingshu.com.cn网站上的联系方式（微信客服号：siweimoxing003)与我们联系。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      theme: "light",
    };
  },
  mounted() {
    if (this.$route.params.theme == "light") {
      this.theme = "light";
    } else if (
      this.$route.params.theme == "dark" ||
      this.$root.config.theme.includes("dark")
    ) {
      this.theme = "dark";
    }
  },
};
</script>
<style>
.dark {
  color: #ebebeb;
  background: #101218;
}
</style>
